<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Geschenkkörbe</h4>
                            <p>Liste aller Geschenkkörbe</p>
                        </div>

                        <div class="card-tools">
                            <button type="button" class="mr-1 btn btn-default" @click="loadGiftwrappers">
                                <i class="fas fa-sync"></i>
                            </button>
                            <button type="button" class="btn btn-primary" @click="createModal">
                                <i class="fas fa-plus-circle"></i> Neuer Geschenkkorb
                            </button>
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row class="mb-3">
                            <b-col md="3">
                                <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>


                            <table class="table table-striped">
                                <thead>
                                    <tr data-widget="expandable-table" aria-expanded="false">
                                        <th>Geschenkkorb-Nummer</th>
                                        <th>Name</th>
                                        <th>Preis</th>
                                        <th style="width:11%;">Aktionen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="giftwrapper in giftwrappers" :key="giftwrapper.id">
                                        <td>{{ giftwrapper.ordernumber }}</td>
                                        <td>{{ giftwrapper.name }}</td>
                                        <td>{{ giftwrapper.price | toCurrency }}</td>
                                        <td>
                                            <router-link class="mr-1 btn btn-success btn-sm" :to="{name: 'giftwrappers.show', params: {id: giftwrapper.id}}" v-if="$auth.check('giftwrapper.show')"><i class="fas fa-fw fa-eye"></i></router-link>
                                            <router-link class="mr-1 btn btn-warning btn-sm" :to="{name: 'giftwrappers.details', params: {id: giftwrapper.id}}" v-if="$auth.check('giftwrapper.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                            <b-button size="sm" @click="deleteGiftwrapper(giftwrapper.id)" variant="danger" v-if="$auth.check('giftwrapper.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                                <b-pagination v-model="currentPage" :per-page="perPage" aria-controls="my-table"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="giftwrapperModal" title="Neuen Geschenkkorb anlegen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk">
                <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

                    <div class="row">
                        <div class="col-md-12">

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label for="company" class="control-label">Firma*</label>
                                        <select v-model="form.company_id" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('company')}">
                                            <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                                        </select>
                                        <has-error :form="form" field="company"></has-error>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="name" class="control-label">Name*</label>
                                        <input v-model="form.name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('name')}">
                                        <has-error :form="form" field="name"></has-error>
                                    </div>

                                    <div class="col-md-4">
                                        <label for="ordernumber" class="control-label">Nummer*</label>
                                        <input v-model="form.ordernumber" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('ordernumber')}">
                                        <has-error :form="form" field="ordernumber"></has-error>
                                    </div>

                                    <div class="col-md-4">
                                        <label for="price" class="control-label">Preis*</label>
                                        <input v-model="form.price" type="number" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('price')}">
                                        <has-error :form="form" field="price"></has-error>
                                    </div>
                                </div>
                            </div>    

                            <div class="form-group">
                                <label for="description" class="control-label">Beschreibung</label>
                                <input v-model="form.description" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('description')}">
                                <!-- <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}"></ckeditor> -->
                                <has-error :form="form" field="description"></has-error>
                            </div>                 

                            <div class="form-group">
                                <label for="image" class="control-label">Bild</label>
                                <b-form-file id="image" v-model="form.image" :class="{'is-invalid': form.errors.has('image')}" />
                                <has-error :form="form" field="image"></has-error>
                            </div>
                        </div>
                    </div>
                </form>
            </b-modal>

    </b-container>
</template>

<script>

// import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';


export default {
    name: "Giftwrappers",
    title: "Geschenkkörbe",
    data() {
        return {
            form: new window.UploadForm({
                id: "",
                name: "",
                ordernumber: "",
                price: "", 
                description: "",
                image: [],
                company_id: '',
            }),
            sortBy: "number",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "ordernumber", label: "Geschenkkorb-Nummer", sortable: true},
                {key: "name", label: "Name", sortable: true},
                {key: "price", label: "Preis", sortable: true},
                {key: "actions", label: "Actions", tdClass: 'hls_action_column'},
            ],
            giftwrappers: [],
            companies: [],
            // editor: ClassicEditor,
            // editorConfig: {
            //     simpleUpload: {
            //         uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
            //         withCredentials: true,
            //         headers: {
            //             'Accept': 'application/json',
            //             'Authorization': 'Bearer '+ this.$auth.token()
            //         }
            //     }
            // },
        }
    },

    methods: {

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createGiftwrappers();
        },

        createModal() {
            this.form.clear();
            this.form.reset();
            this.form.company_id = this.companies[0].id;
            this.$bvModal.show("giftwrapperModal");
        },

        deleteGiftwrapper(id) {
            this.$swal({
                    title: "Möchtest du den Geschenkkorb wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.form
                            .delete("/giftwrappers/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Geschenkkorb erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    });
                                this.loadGiftwrappers();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        createGiftwrappers() {
            this.form
                .post("/giftwrappers")
                .then(() => {
                    this.$bvModal.hide("giftwrapperModal");
                    this.$swal({
                        icon: "success",
                        title: "Geschenkkorb wurde angelegt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                    });
                    this.loadGiftwrappers();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadCompanies() {
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                    //this.form.company_id = this.companies[0].id;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },


        async loadGiftwrappers() {
            await this.axios
                .get("/giftwrappers")
                .then((response) => {
                    this.giftwrappers = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    created() {
        this.loadGiftwrappers();
        this.loadCompanies();
    },



}
</script>

<style>

</style>